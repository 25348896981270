import axios from "axios"
import { CategoryClick, CategoryImpression, SearchClick, SearchImpression } from "@/types"
import { findCustomer } from "@/core/tagging"
import logger from "@/core/logger"
import settings from "@/core/settings"
import getCustomerId from "@/core/customerId"
import { AnalyticsType, Endpoint } from "./types"

export async function reportAnalytics(type: "search", endpoint: "impression", data: SearchImpression): Promise<unknown>
export async function reportAnalytics(type: "search", endpoint: "click", data: SearchClick): Promise<unknown>
export async function reportAnalytics(
  type: "category",
  endpoint: "impression",
  data: CategoryImpression
): Promise<unknown>
export async function reportAnalytics(type: "category", endpoint: "click", data: CategoryClick): Promise<unknown>

export async function reportAnalytics(type: AnalyticsType, endpoint: Endpoint, data: object) {
  const customerData = findCustomer()

  try {
    const customerId = await getCustomerId()
    if (!customerId) {
      logger.warn("Skipping analytics event, no customer id defined")
      return
    }
    await axios.post(`${settings.server}/analytics/${type}/${endpoint}`, data, {
      params: {
        merchant: settings.account,
        c: customerId,
        customerReference: customerData?.customer_reference || undefined
      }
    })
  } catch (e) {
    logger.warn(`Failed to send ${type} ${endpoint} analytics`, e)
  }
}

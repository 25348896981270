import { CategoryEventMetadata, SearchEventMetadata } from "@/types"
import { sessionStore } from "@/core/store"
import { SearchTrackOptions } from "./types"

function createMetadataCache<T>() {
  let metadata: T | undefined

  return {
    get: () => metadata,
    set: (data: T) => {
      metadata = {
        ...(metadata ?? {}),
        ...data
      }
    },
    reset: () => {
      metadata = undefined
    }
  }
}

export const serpMetadataCache = createMetadataCache<SearchEventMetadata>()
export const autocompleteMetadataCache = createMetadataCache<SearchEventMetadata>()
export const categoryMetadataCache = createMetadataCache<CategoryEventMetadata>()

export function getMetadataCache<T extends SearchTrackOptions>(type: T) {
  return {
    serp: serpMetadataCache,
    autocomplete: autocompleteMetadataCache,
    category: categoryMetadataCache
  }[type]
}

const organicQueriesCacheKey = "nosto:search:organicQueries" as const

export const organicQueriesCache = {
  get() {
    return sessionStore.getAsJson<string[]>(organicQueriesCacheKey) || []
  },
  push(query: string) {
    sessionStore.setAsJson(organicQueriesCacheKey, [...this.get(), query].slice(-100))
  }
}

let resultId: string | undefined

export const resultIdCache = {
  get: () => resultId,
  set: (value: string) => {
    resultId = value
  }
}

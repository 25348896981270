import pageTypeFromTagging from "@/core/page-type"
import context from "@/core/context"
import settings from "@/core/settings"
import { pageTagging } from "@/core/tagging"
import { PushedProduct } from "@/types"

interface Data {
  pageType: string | null
  categories: string[] | null
  products: PushedProduct[] | null
}

const data: Data = {
  pageType: null,
  categories: null,
  products: null
}

function categories(): string[] {
  return data.categories || pageTagging().categories || []
}

function products(): PushedProduct[] {
  if (data.products?.length) {
    // merging tagging into data
    const products = (pageTagging().products || []).reduce<Record<string, PushedProduct>>(
      (acc, p) => (acc[p.product_id] = p) && acc,
      {}
    )
    return data.products.map(p => Object.assign({}, products[p.product_id] || {}, p))
  }
  return pageTagging().products || []
}

function productFields<K extends keyof PushedProduct>(field: K): PushedProduct[K][] {
  return products()
    .map(p => p[field])
    .filter(p => p != null)
}

function update(d: Partial<Data>): void {
  const filtered = Object.fromEntries(Object.entries(d).filter(([key]) => key in data))
  Object.assign(data, filtered)
}

function pageType(): string | null {
  return data.pageType || pageTypeFromTagging()
}

function url(): string {
  return context.siteUrlCleaned.replace(/nostodebug=[^&]*&|[?&]nostodebug=[^&]*$/, "")
}

function productFieldMapping<K extends keyof PushedProduct>(field: K) {
  return {
    [field]: () => productFields(field)
  }
}

export default {
  update,
  page_type: () => {
    const pt = pageType()
    return !pt && settings.pageTypeFiltersForUntaggedPages ? "NOT_TAGGED" : pt
  },
  page_types: () => [pageType()],
  product_ids: () => productFields("product_id"),
  categories: () => categories().concat(...productFields("category")),
  brands: () => productFields("brand"),
  tag1: () => new Array<string>().concat(...productFields("tags1")),
  tag2: () => new Array<string>().concat(...productFields("tags2")),
  tag3: () => new Array<string>().concat(...productFields("tags3")),
  tags: () =>
    new Array<string>().concat(...productFields("tags1"), ...productFields("tags2"), ...productFields("tags3")),
  // popups compatibility
  urls: url,
  url,
  referer_urls: () => context.referer?.href,
  url_parameters: () => {
    const params = (context.siteUrl.search || "?").substring(1)
    try {
      return decodeURIComponent(params).split("&")
    } catch (e) {
      return params.split("&")
    }
  },
  ...productFieldMapping("price"),
  ...productFieldMapping("list_price"),
  availability: () =>
    productFields("availability").map(v =>
      v.startsWith("http://schema.org/") ? v.substring(18 /* "http://schema.org/".length */) : v
    ),
  discounted: () =>
    products()
      .filter(p => p.price && p.list_price)
      .map(p => p.price < p.list_price!)
      .map(String)
}

import { AnalyticEventProperties, CategoryClick, Maybe, SearchClick, SearchEventMetadata } from "@/types"
import { sessionStore } from "@/core/store"
import { putAttribution } from "@/core/parameterlessAttribution"
import logger from "@/core/logger"
import { SearchProduct } from "../types"
import { getVariations } from "../variations"
import { checkSearchMetadata, getEventProperties } from "./helpers"
import { reportAnalytics } from "./api"
import { SearchTrackOptions } from "./types"
import { getMetadataCache } from "./cache"

const searchClickKey = "nosto:search:clickStorage"
const categoryClickKey = "nosto:category:clickStorage"

export async function reportSearchClick() {
  const searchClickData = sessionStore.getAsJson<SearchClick>(searchClickKey)
  if (searchClickData) {
    try {
      searchClickData.metadata = checkSearchMetadata("click", searchClickData.metadata)
      sessionStore.remove(searchClickKey)
      await reportAnalytics("search", "click", searchClickData)
    } catch (e) {
      logger.error("Could not report search click", e)
    }
  }

  const categoryClickData = sessionStore.getAsJson<CategoryClick>(categoryClickKey)
  if (categoryClickData) {
    try {
      sessionStore.remove(categoryClickKey)
      await reportAnalytics("category", "click", categoryClickData)
    } catch (e) {
      logger.error("Could not report category click", e)
    }
  }
}

/**
 * Record search click event
 */
export function recordSearchClick(type: SearchTrackOptions, hit: SearchProduct) {
  if (!type || !["serp", "autocomplete", "category"].includes(type)) {
    throw new Error(`Invalid search click track option: ${type}`)
  }

  const properties = getEventProperties(getVariations())

  if (type === "category") {
    const metadataCache = getMetadataCache(type)
    const metadata = metadataCache.get()
    if (metadata?.category || metadata?.categoryId) {
      sessionStore.setAsJson<CategoryClick>(categoryClickKey, { productId: hit.productId!, metadata, properties })
    }

    return
  }

  const metadataCache = getMetadataCache(type)

  if (type === "autocomplete") {
    if ("keyword" in hit) {
      return
    }

    const metadata = metadataCache.get()
    if (metadata) {
      metadata.isAutoComplete = true
      return storeSearchClick(hit.productId, metadata, hit.url!, properties)
    }
  } else {
    const metadata = metadataCache.get()
    if (metadata) {
      metadata.isAutoComplete = false
      return storeSearchClick(hit.productId, metadata, hit.url!, properties)
    }
  }
}

export function storeSearchClick(
  productId: string = "",
  metadata: SearchEventMetadata,
  productUrl: string,
  properties: Maybe<AnalyticEventProperties>
) {
  metadata = checkSearchMetadata("click", metadata)
  sessionStore.setAsJson<SearchClick>(searchClickKey, { productId, metadata, properties })
  // add VP ref via parameterlessAttribution
  if (productUrl && metadata.resultId) {
    putAttribution(productUrl, { ref: metadata.resultId })
  }
}

import simpledialog from "@nosto/simple-dialog"
import settings from "@/core/settings"
import ctx from "@/core/context"
import bus from "@/core/api/bus"
import { fetchPopupData, ResponseData } from "./requests/fetchPopupData"
import { isAbandonedCartPopup } from "./utils/utils"
import { createDialog, hideDialog as baseHideDialog, showDialog as baseShowDialog, closeDialog } from "./dialog"
import { Props, ReqData } from "./types"
import { setupCouponButtons } from "./setup/setupCouponButtons"
import { extractAndCacheCouponCode } from "./setup/extractAndCacheCouponCode"
import { attachEventHandlers } from "./setup/attachEventHandlers"
import { loadResponseDataScripts } from "./setup/loadResponseDataScripts"
import { clearPopupAttributes, readPopupAttributes, writePopupAttributeWithPreview } from "./popupAttributes"
import { reportPopupAnalytics } from "./analytics"
import visit from "@/core/visit"

export function discountPopup(props: Props) {
  const { popupId, cartSize, cartTotal, trigger, forcedSegments, preview } = props

  // campaignId is set on popup response, it's only used for statistics
  let campaignId: string | null = null

  if (!popupId) {
    throw new Error("No popup id")
  }
  const reqData: ReqData = {
    account: settings.account,
    c: visit.getCustomerId(),
    fs: forcedSegments,
    popupId,
    cartSize,
    cartTotal,
    email: undefined,
    preview: false,
    campaignId: undefined
  }

  reqData.preview = !!props.preview
  if (reqData.preview) {
    reqData.campaignId = props.campaignId
  } else {
    reqData.campaignId = readPopupAttributes(popupId).campaignId
  }

  let dialog: HTMLDivElement
  let modal: ReturnType<typeof simpledialog>

  const showDialog = () =>
    baseShowDialog({
      ...props,
      dialog,
      modal
    })

  const hideDialog = () =>
    baseHideDialog({
      ...props,
      dialog,
      modal
    })

  if (ctx.popupShown) {
    // some popup is shown, skip for now
    return {
      close
    }
  }
  ctx.popupShown = true

  function onDataFetched(responseData: ResponseData) {
    if (!popupId) {
      return
    }

    if (responseData.neverShowInSession) {
      writePopupAttributeWithPreview(popupId, "state", preview, "closed")
      ctx.popupShown = false
      return
    }

    campaignId = responseData.campaign_id
    reqData.campaignId = campaignId
    // Not popup shown as ribbon or preview
    if (campaignId && readPopupAttributes(popupId).state !== "shown" && !preview) {
      reportPopupAnalytics(campaignId, "triggered")
    }

    if (!responseData.html) {
      // clean cookie if present
      clearPopupAttributes(popupId)
      ctx.popupShown = false
      return
    }

    dialog = loadResponseDataScripts(responseData)

    modal = createDialog({
      effect: props.effect,
      responseData
    })

    extractAndCacheCouponCode({
      popupId,
      responseData,
      preview
    })

    attachEventHandlers({
      ...props,
      reqData,
      dialog,
      modal,
      campaignId,
      popupId
    })

    setupCouponButtons({ dialog })

    // SHOW
    // has pop-up already been shown?
    if (!isAbandonedCartPopup(trigger) && readPopupAttributes(popupId).state === "shown") {
      // does the ribbon exist?
      const ribbon = dialog.querySelector("#NostoRibbon")
      if (ribbon) {
        // show ribbon instead of pop-up
        hideDialog()
        bus.emit("popupribbonshown", {
          campaignId: popupId
        })
        return
      }
    }

    // show pop-up
    showDialog()
    // store 'popup shown' into cookies to avoid showing pop-up after page refreshes
    writePopupAttributeWithPreview(popupId, "state", preview, "shown")
    writePopupAttributeWithPreview(popupId, "campaignId", preview, campaignId)
    bus.emit("popupopened", {
      campaignId: popupId,
      type: trigger
    })
    // update usage statistics
    if (!preview) {
      reportPopupAnalytics(campaignId, "shown")
    }
  }

  void (async () => {
    const response = await fetchPopupData({ ...props, popupId, reqData })
    if (response) {
      onDataFetched(response)
    }
  })()

  return {
    close: () => closeDialog({ ...props, dialog, modal })
  }
}

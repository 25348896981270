import context from "@/core/context"
import windows from "@/core/windows"
import logger from "@/core/logger"
import type { API } from "@/core/api"
import { isPromise } from "@/utils/isPromise"
import { RenderMode } from "@/types"
import { isAlreadyLogged } from "@/utils/error"

type NostojsCallback = (api: API) => unknown

export type InitOptions = {
  responseMode?: RenderMode
  disableAutoLoad?: boolean
  disableRecommendations?: boolean
}

/**
 * Main function to interact with the Nosto API.
 *
 * @group Core
 */
export type nostojs = {
  (cb: NostojsCallback): void
  /** @hidden */
  q?: NostojsCallback[]
  /** @hidden */
  o?: InitOptions
}

export default function processQueuedAPICalls(api: API) {
  const { shift } = []

  /**
   * Runs the specified API callback function with a parameter being api
   *
   * @param {Function} callback the callback to be executed in the api
   * @return {Promise} a promise indicating the result
   */
  async function apiCaller(callback: NostojsCallback) {
    try {
      const result = callback(api)
      if (isPromise(result)) {
        // await promise to get the rejection also logged
        return await result
      }
      return Promise.resolve()
    } catch (err) {
      if (!isAlreadyLogged(err)) {
        logger.warn("Error in API callback", err)
      }
      return Promise.reject(err)
    }
  }

  // Check if there was a nostojs stub on the page. If so, it is is processed and
  // if not - a new one is created.
  if (context.loader) {
    const promises: Promise<unknown>[] = []
    const { q } = context.loader
    if (q) {
      // first process all calls from the queue
      while (q && q.length > 0) {
        // @ts-expect-error unsafe assignment
        const c: Callback = shift.apply(q)
        promises.push(apiCaller(c))
      }
    }

    // replace queue to be instant call
    // @ts-expect-error legacy field, we don't know where this comes from
    context.loader.q = {}
    // @ts-expect-error legacy field, we don't know where this comes from
    context.loader.q.push = apiCaller

    /**
     * All the promises that are returned from the callback functions are chained
     * together and a single promise containing the result of all promises is
     * returned.
     * While this serves little or no use in production, it is very helpful for
     * testing API invocations synchronously.
     *
     * nostojs(api => api.loadRecommendations()).then(() => console.log("done"))
     *
     * The above example will only print done once Nosto has loaded, the "Q",
     * initialized and once the load-recommendations call has finished making the
     * underlying XHR request (using Axios).
     */
    Promise.all(promises)
      // eslint-disable-next-line promise/prefer-await-to-then
      .then(() => {
        // @ts-expect-error legacy field, we don't know where this comes from
        // eslint-disable-next-line
        context.loader.resolve || (() => {})({ api })
      })
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch(err => {
        // @ts-expect-error legacy field, we don't know where this comes from
        // eslint-disable-next-line
        context.loader.reject || (() => {})(err)
      })
  } else {
    // Looks like there was no nostojs stub on the page. Creating a new stub.
    windows.site.nostojs = apiCaller
  }
}

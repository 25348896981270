/* Internal helper functions */
import type { ReqData } from "../types"

export function isAbandonedCartPopup(trigger: string) {
  return trigger === "abandonedCart"
}

function removeEmpty(params: ReqData) {
  return Object.keys(params).reduce<Record<string, string>>((result, key) => {
    const val = params[key]

    if (val !== undefined && val !== null && val !== "") {
      result[key] = String(val)
    }

    return result
  }, {})
}

export const reqDataParams = (rq: ReqData) => new URLSearchParams(removeEmpty(rq)).toString()

export function validateEmail(email: string) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return email && re.test(email)
}

import context, { Context } from "@/core/context"
import settings, { Settings } from "@/core/settings"
import placements from "@/placements"
import visit from "@/core/visit"
import internal from "@/core/api/internal"
import logger from "@/core/logger"
import { PushedCustomer } from "@/types"
import { Level } from "../logger/types"
import { Overlay } from "@/overlay/overlay"

function noop() {}

const setResponseMode = noop
const createSession = internal.createSession
const defaultSession = internal.defaultSession
const createRecommendationRequest = internal.createRecommendationRequest
const setAutoLoad = internal.setAutoLoad
const isAutoLoad = internal.isAutoLoad
const setRecommendationsEnabled = internal.setRecommendationsEnabled
const listen = internal.listen
const loadRecommendations = internal.loadRecommendations
const load = internal.load
const pageTagging = internal.extractTagging
const loadCartPopupRecommendations = internal.loadCartPopupRecommendations
const reportAddToCart = internal.recommendedProductAddedToCart

const captureError = (error: unknown, reporter: string, level: Level = "warn") => {
  logger[level](reporter, error)
}

const recommendedProductAddedToCart = internal.recommendedProductAddedToCart
const experiments = internal.setExperiments

const customer = (customer: PushedCustomer) => {
  // for public API calls, we are explicitly setting the source
  // eslint-disable-next-line no-param-reassign
  customer.source = "api"
  return internal.setCustomer(customer)
}

const popupCampaigns = internal.popupCampaigns
const reloadOverlay = internal.reloadOverlay
const openPopup = internal.openPopup
const enablePopup = internal.enablePopup
const disablePopup = internal.disablePopup
const resendCartContent = internal.resendCartContent
const resendCartTagging = internal.resendCartTagging
const resendCustomerTagging = internal.resendCustomerTagging
const sendTagging = internal.sendTagging
const addSegmentCodeToVisit = internal.addSegment
const removeCampaigns = internal.removeCampaigns
const showPlacementPreviews = internal.showPlacementPreviews

type Install = {
  context: Context
  settings: Settings
  overlay: Overlay
}

const install = (callbackFn: (cb: Install) => void) => {
  // noinspection JSUnusedGlobalSymbols
  callbackFn({
    context,
    settings,
    overlay: internal.getOverlay()
  })
}

const getSearchSessionParams = internal.getSearchSessionParams
const search = internal.search
const recordSearch = internal.recordSearch
const recordSearchClick = internal.recordSearchClick
const recordSearchSubmit = internal.recordSearchSubmit
const recordAttribution = internal.recordAttribution

/**
 * @group Core
 */
const api = {
  /** @hidden */
  internal,
  // TODO docs
  placements,
  // TODO docs
  visit,
  /**
   * @deprecated since this was a quick hack for usage in Codepen.IO
   * @hidden
   */
  setResponseMode,
  /**
   * API method create a new session. This should be used when you might want to
   * have multiple sessions on the same page. In most cases, using
   * @see {@link Api#defaultSession} will suffice.
   *
   * @deprecated
   * @hidden
   * @return {Session} the newly created session
   */
  createSession,
  /**
   * API method to access the default session. This should only be used when implementing
   * Nosto on a single-page application atop a framework such as React, Vue, Angular or
   * the likes.
   * <br/><br/>
   * If you are not using a single-page application but require programmatic access to the
   * Nosto request builder use {@link Api#createRecommendationRequest}.
   *
   * @public
   * @return {Session} the instance of the default session
   */
  defaultSession,
  /**
   * API method to create a recommendation request. This should only be used when you
   * require programmatic access to the Nosto request builder.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and  you are implementing Nosto, you must use the {@link Api#defaultSession}
   * method.
   *
   * @public
   * @param {Object} flags a set of flags to customise to request behaviour (eg. {"includeTagging":true}
   * to initialise the request from the page tagging.
   * @return {RequestBuilder} the instance of the request.
   */
  createRecommendationRequest,
  /**
   * API method to disable the automatic initialization of Nosto. This should be used in
   * cases when you want to manually load content.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you must disable
   * auto-loading.
   *
   * @example
   * nostojs(api => api.setAutoLoad(false))
   * nostojs(api => api.load())
   *
   * @param {Boolean} flag A true or false value indicating whether to automatically load or not
   */
  setAutoLoad,
  /**
   * API method to check the status of the autoload flag. This should be used for debugging
   * purposes only.
   *
   * @deprecated since it served little or no purpose and clutters the API erasure
   * @hidden
   * @return {Boolean}
   */
  isAutoLoad,
  /**
   * @deprecated
   * @hidden
   * @param {Boolean} flag A true or false value indicating whether to disable placements or not
   */
  setRecommendationsEnabled,
  /**
   * API method to register a listener for JS API events. Nosto's JS API dispatches
   * multiple events across the session lifetime.
   * <br/><br/>
   * Due to the wide gamut of events dispatched, listing them all is still a work in
   * progress.
   *
   * @example <caption>to log a message whenever a request is made to Nosto</caption>
   * nostojs(api => api.listen('taggingsent'), () => console.log("The tagging was sent"));
   *
   * @param {String} phase
   * @param {Function} cb the callback function to be invoked
   */
  listen,
  /**
   * API method to reload all onsite recommendations and content. This should only be used when need to
   * reload all recommendations and content e.g. on a overlay modal.
   * <br/><br/>
   * Incorrect or extraneous usage of this method will lead to skewed page-view
   * statistics, ad every invocation of this method results in a +1 page-view count.
   *
   * @public
   * @return {Promise}
   */
  loadRecommendations,
  /**
   * API method to load Nosto. This function is automatically invoked when the page loads.
   *
   * @example <caption>to manually load recommendations after DOM ready</caption>
   * nostojs(api => api.load());
   *
   * @return {Promise}
   */
  load,
  /**
   * API method that to debug the state the page tagging. This is useful for debugging
   * what Nosto sees. You are able to see all the page tagging via the debug toolbar.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, if used, this method will always return
   * an empty object (as there shouldn't be any tagging/metadata on the page).
   * <br/><br/>
   * This is only for debugging purposes and should never be used in a production environment
   *
   * @example <caption>to log the page state to the console</caption>
   * nostojs(api => console.log(api.pageTagging()));
   *
   * @return {Object} the representation of the page tagging
   */
  pageTagging,
  /** @hidden */
  loadCartPopupRecommendations,
  /**
   * @public
   * @param cartItemId
   * @param nostoElementId
   * @return {Promise<Object>}
   */
  reportAddToCart,
  /** @hidden */
  captureError,
  /**
   * @public
   * @param {String} productId
   * @param {String} nostoElementId
   * @return {Promise<Object>}
   */
  recommendedProductAddedToCart,
  /**
   * API method to force the current session to be a part of the given experiment.
   *
   * @deprecated since no one knows what goes in here.
   * @hidden
   * @param experiments the experiments to move the session into
   * @return {Promise<Object>}
   */
  experiments,
  /**
   * API method to resend the provided customer details to Nosto. This is used in situations
   * when the customer details is loaded after the client script initialization.
   * <br/><br/>
   * If the current customer is not provided, you will not be able to leverage features such as
   * triggered emails. While it is recommended to always provide the details of
   * the currently logged in customer, it may be omitted if there are concerns
   * about privacy or compliance.
   * <br/><br/>
   * It is not recommended to pass the current customer details to the request
   * builder but rather use the customer tagging.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern. You should be using the Session API @see {@link Session#setCustomer}
   * to provide the customer information.
   * <br/><br/>
   * This method is legacy method and therefore named incorrectly. Is the customer equivalent
   * of the resendCartContent method and actually should be called resendCustomerDetails.
   *
   * @todo deprecate this method and rename it to resendCustomerDetails
   * @example
   * nostojs(api => api.customer({
   *   first_name: "Mridang",
   *   last_name: "Agarwalla",
   *   email: "mridang@nosto.com",
   *   newsletter: false,
   *   customer_reference: "5e3d4a9c-cf58-11ea-87d0-0242ac130003"
   * }))
   *
   * @public
   * @param {Customer} customer the details of the currently logged in customer
   * @return {Promise<Object>}
   */
  customer,
  /** @hidden */
  popupCampaigns,
  /** @hidden */
  reloadOverlay,
  /** @hidden */
  openPopup,
  /** @hidden */
  enablePopup,
  /** @hidden */
  disablePopup,
  /**
   * API method to resend the cart content to Nosto. This is used in situations
   * when the cart tagging is loaded after the client script initialization.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern. You should be using the Session API @see {@link Session#setCart}
   * to provide the cart information.
   *
   * @example
   * nostojs(api => api.resendCartContent({
   *   items: [
   *     product_id: "101",
   *     sku_id: "101-S",
   *     name: "Shoe",
   *     unit_price: 34.99
   *     price_currency_code: "EUR"
   *   ]
   * }))
   *
   * @public
   * @param {Cart} cart content of the cart
   * @return {Promise<Object>}
   */
  resendCartContent,
  /**
   * API method to resend the cart tagging to Nosto. This is used in situations
   * when the cart tagging is loaded after the client script initialization. This method
   * reads all metadata having the class "nosto_cart" and sends the extracted cart
   * information to Nosto.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern. You should be using the Session API @see {@link Session#setCart}
   * to provide the cart information.
   *
   * @public
   * @example
   * nostojs(api => api.resendCartTagging())
   *
   * @return {Promise<Object>}
   */
  resendCartTagging,
  /**
   * API method to resend the customer tagging to Nosto. This is used in situations
   * when the customer tagging is loaded after the client script initialization. This method
   * reads all metadata having the class "nosto_customer" and sends the extracted customer
   * information to Nosto.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern. You should be using the Session API @see {@link Session#setCustomer}
   * to provide the customer information.
   *
   * @public
   * @example
   * nostojs(api => api.resendCustomerTagging())
   *
   * @return {Promise<Object>}
   */
  resendCustomerTagging,
  /**
   * API method to resend all the tagging to Nosto. This is used in situations when
   * the cart and the customer tagging is loaded after the client script initialization.
   *
   * While you can use resendCartTagging and the resendCustomerTagging to achieve the
   * same - this method will make a single request to Nosto.
   * <br/><br/>
   * If your site is a single-page application atop a framework such as React, Vue, Angular or
   * the likes, and you are implementing Nosto using the {@link Session} API, you do not
   * ever need this method. Nosto implementations on the single-page applications don't
   * rely on the tagging metadata and therefore, usage of this method is indicative of an
   * incorrect usage pattern.
   *
   * @public
   * @example
   * nostojs(api => api.sendTagging())
   *
   * @return {Promise<Object>}
   */
  sendTagging,
  /**
   * API method to manually add a given segment code to the the current user.  This
   * is used in situations when you want to segment users based on external logic.
   * <br/><br/>
   * Sending a segment code does not automatically create the corresponding segment.
   *
   * @public
   * @example <caption>to add a user to segment when they've used a discount code</caption>
   * nostojs(api => api.addSegmentCodeToVisit('discount code user'))
   *
   * @param {String} segment
   */
  addSegmentCodeToVisit,
  /**
   * Removes injected content from the supplied divIds
   * If campaign was injected statically, then static placement just clears its contents.
   * If dynamically, the injected element gets removed from DOM
   * @param {String[]} divIds
   */
  removeCampaigns,
  /**
   * @deprecated since this is for debug-toolbar usage only and should not be in the public API
   * @hidden
   * @param placement
   * @param content
   */
  showPlacementPreviews,
  /**
   * @deprecated since this is for debug-toolbar usage only and should not be in the public API
   * @hidden
   * @param callbackFn
   */
  install,
  /**
   * API method to retrieve search affinities and segments and transform it to partial search query.
   * <br/><br/>
   * Results are cached to sessionStorage and is refreshed after cacheRefreshInterval
   * @example
   * nostojs(api => api.getSearchSessionParams({ maxWait: 2000, cacheRefreshInterval: 60000 }).then((sessionParams) => sessionParams))
   *
   * @public
   * @param {SearchSessionParamsOptions} options
   * @returns {Promise<SearchSessionParams>}
   */
  getSearchSessionParams,
  /**
   * Search function which requests graphql search endpoint.
   * <br/><br/>
   * @example
   * nostojs(api => {
   *  api.search({
   *    query: 'green',
   *    products: {
   *     fields: ['name', 'customFields.key', 'customFields.value']
   *    }
   *  })
   *    .then(response => response)
   *    .catch(err => err)
   *  })
   * })
   *
   * @public
   * @param {SearchQuery} query Search query.
   * @param {SearchOptions=} options Search custom options.
   * @returns {Promise<SearchResult>}
   */
  search,
  /**
   * Record search event, should be send on any search
   *
   * @param {SearchTrackOptions} type search type
   * @param {SearchQuery} query Full API query
   * @param {SearchResult} response {object} Full API response
   */
  recordSearch,
  /**
   * Record search click event
   *
   * @param {SearchTrackOptions} type search type
   * @param {object} hit Full hit object from search API
   */
  recordSearchClick,
  /**
   * Record search submit event (e.g. search form submit). Required to track organic searches.
   *
   * @param {string} query Search query
   */
  recordSearchSubmit,
  // TODO docs
  recordAttribution
}

export default api

/** @hidden */
export type API = typeof api

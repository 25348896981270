import logger from "@/core/logger"
import * as v from "valibot"
import { isError } from "./isError"
import { ValidationError } from "./error"

function getValidationMessage(error: unknown, data: unknown): string {
  let message = ""
  if (error instanceof v.ValiError) {
    message = JSON.stringify(v.flatten(error).nested)
  } else {
    message = isError(error) ? error.message : String(error)
  }
  return `There are errors: ${message} in your payload: ${JSON.stringify(data)}`
}

export function createAssertFn<S extends v.BaseSchema>(schema: S) {
  return function validate(data: v.Input<S>) {
    try {
      v.parse(schema, data)
    } catch (error) {
      const errorMessage = getValidationMessage(error, data)
      logger.warn(errorMessage)
      throw new ValidationError(errorMessage)
    }
  }
}

export function createValidateFn<S extends v.BaseSchema>(schema: S) {
  return function validate(data: v.Input<S>) {
    const result = v.safeParse(schema, data)
    if (!result.success) {
      const valiError = new v.ValiError(result.issues)
      const errorMessage = getValidationMessage(valiError, data)
      logger.warn(errorMessage)
    }
    return result.success
  }
}
